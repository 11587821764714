import { Col, Input, InputNumber, PaginationProps, Row, Select, Table } from 'antd';
import { useEffect, useState } from 'react';
import Highlighter from 'react-highlight-words';
import '../App.css';
import { BANK } from '../constants';
import { searchSaoKe } from '../services/YagiService';
import { removeAccents } from '../utils/helper';
const { Search } = Input;

function Homepage() {
  const [data, setData] = useState([] as any);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [searchText, setSearchText] = useState('');
  const [bankCode, setBankCode] = useState('');
  const [amount, setAmount] = useState('');
  const [amountCompare, setAmountCompare] = useState('=');

  const columns = [
    {
      title: 'Ngân hàng',
      dataIndex: 'bankCode',
      key: 'bankCode',
      align: 'center',
      render: (value: any) => {
        return BANK[value];
      }
    },
    {
      title: 'Ngày',
      dataIndex: 'transactionDate',
      key: 'transactionDate',
      align: 'center',
      render: (text: any, record: any) => {
        return (
          <>
            <p>{text}</p>
            <span>{record?.transactionTime}</span>
          </>
        )
      }
    },
    {
      title: 'Số tiền',
      dataIndex: 'credit',
      key: 'credit',
      align: 'right',
      render: (value: any) => {
        return `${formatToVND(value)}`;
      }
    },
    {
      title: 'Nội dung',
      dataIndex: 'transactionContent',
      key: 'transactionContent',
      render: (text:any, record :any, index :any) => (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[removeAccents(searchText)]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ),
    },
    {
      title: 'Mã GD',
      dataIndex: 'docNo',
      key: 'docNo',
      align: 'center'
    },
  ] as any;
  
  const creditCompareOpts = [
    {
      value: '=',
      label: '=',
    },
    {
      value: '>=',
      label: '>=',
    },
    {
      value: '<=',
      label: '<=',
    },
  ];

  const bankCodeOpts = [
    {
      value: '',
      label: '--Chọn--'
    },
    {
      value: 'VCB',
      label: 'Vietcombank',
    },
    {
      value: 'VTB',
      label: 'Vietinbank',
    },
    {
      value: 'BIDV',
      label: 'BIDV',
    },
  ]

  const formatToVND = (number: number) => {
    return number.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' });
  };

  const fetchData = async () => {
    const res = await searchSaoKe({page: page, limit: limit, search: removeAccents(searchText), bankCode: bankCode, amount: amount, amountCompare: amountCompare}) as any;
    const items = res?.data?.data;
    if(items && Array.isArray(items) && items.length > 0) {
      setData(items);
    } else {
      setData([]);
    }
    setTotalRecords(res?.data?.totalRecords);
  }

  const onShowSizeChange: PaginationProps['onShowSizeChange'] = (current, pageSize) => {
    setPage(current);
    setLimit(pageSize);
  };

  const handleSearch = (value:any) => {
    setSearchText(value);
  };

  const onChangeSearch = (e:any) => {
    const value = e?.target?.value;
    setSearchText(value);
  };

  useEffect(() => {
    fetchData();
  }, [page, limit, searchText, bankCode, amount, amountCompare]);

  return (
    <div className="text-lg">
      <div className="flex justify-center items-center mt-8 mb-8">
        <img src='logo-saoke-var.png' alt='log' width={217} height={50} className='center'/>
      </div>
      <div className="px-8">
        <p className='font-bold uppercase'>Dữ liệu được cung cấp theo sao kê tài khoản của Mặt trận Tổ quốc Việt Nam.</p>
        <p><span className='font-bold'>Sao kê TK Vietcombank của MTTQ:</span> từ ngày 01/09/2024 đến ngày 10/09/2024.</p>
        <p><span className='font-bold'>Sao kê TK Vietinbank của MTTQ:</span> từ ngày 10/09/2024 đến ngày 12/09/2024.</p>
        <p><span className='font-bold'>Sao kê TK BIDV của MTTQ:</span> từ ngày 01/09/2024 đến ngày 12/09/2024.</p>
      </div>
      <div className="text-center mt-4 px-2">
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={4} lg={3} xl={3}>
            <Select
              placeholder="Chọn ngân hàng"
              options={bankCodeOpts}
              id='bankCode'
              onChange={(value: any) => {
                setBankCode(value);
              }}
              style={{width: '100%'}}
            />
          </Col>
          
          <Col xs={24} sm={12} md={4} lg={3} xl={3}>
            <Select
              placeholder="So sánh số tiền"
              options={creditCompareOpts}
              id='creditCompare'
              style={{width: '100%'}}
              onChange={(value:any) => {
                setAmountCompare(value);
              }}
            />
          </Col>

          <Col xs={24} sm={12} md={6} lg={6} xl={4}>
           <InputNumber prefix="VND" placeholder='Số tiền' id='credit'
            style={{width: '100%', height: '32px', textAlign: 'right'}}
            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => `${value}`.replace(/\$\s?|(,*)/g, '')}
            onChange={(value: any) => {
              setAmount(value)
            }}
            />
          </Col>

          <Col xs={24} sm={12} md={10} lg={12} xl={14}>
            <Search
              placeholder="Search..."
              onSearch={handleSearch}
              onChange={onChangeSearch}
              className='mb-8 col-span-9'
            />
          </Col>
        </Row>

        <Table dataSource={data} columns={columns} className='px-2' pagination={{
          defaultCurrent: 1,
          defaultPageSize: 50,
          pageSizeOptions: [50, 100],
          showSizeChanger: true,
          total: totalRecords,
          onShowSizeChange: onShowSizeChange,
          onChange: onShowSizeChange
        }} 
        scroll={{ x: 800 }}
        />
      </div>
    </div>
  );
}
  
  export default Homepage;